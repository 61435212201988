import React from 'react';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { User } from '../models';
import useLocalStorage from '../hooks/useLocalStorage';
import useReadLocalStorage from '../hooks/useReadLocalStorage';

interface IUserContext {
  user: User;
  setUser: React.Dispatch<React.SetStateAction<User>>;
}

export const UserContext = React.createContext<IUserContext>({
  user: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setUser: () => {},
});

interface UserContextProviderProps {
  children: React.ReactNode;
}

export function UserContextProvider({ children }: UserContextProviderProps) {
  const session = useSession();
  const [user, setUser] = React.useState<User>(null);
  const router = useRouter();
  const [selectedClientUser, setSelectedClientUser] = useLocalStorage(
    'currentClientUser',
    {} as User
  );
  const currentClientUser = useReadLocalStorage('currentClientUser');
  React.useEffect(() => {
    if (session.status === 'authenticated' && currentClientUser === null) {
      setUser(session.data.user as User);
      setSelectedClientUser(session.data.user as User);
    } else if (currentClientUser) {
      setUser(currentClientUser as User);
    } else if (
      session.status === 'unauthenticated' &&
      router.pathname !== '/reset-password/[token]' &&
      router.pathname !== '/en/tracking/[_id]' &&
      router.pathname !== '/en/tracking' &&
      router.pathname !== '/sales/[slug]' &&
      router.pathname !== '/privacy' &&
      router.pathname !== '/data-deletion' &&
      router.pathname !== '/terms' &&
      router.pathname !== '/whatsapp-data-protection' &&
      router.pathname !== '/app/freights/[_id]?from=whatsapp'
    ) {
      router.push('/');
      setUser(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session?.data?.user, session?.status]);
  const value = React.useMemo(() => ({ user, setUser }), [user]);
  if (session.status === 'loading') {
    return null;
  }
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}
